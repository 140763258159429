import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import { jsx } from "theme-ui";
import { PageLayout } from "../layouts/PageLayout";
import { fontSize } from "../gatsby-plugin-theme-ui/tokens";
import { PrintItButton } from "../lib/reusable-ui/PrintItButton";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Box = makeShortcode("Box");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <PageLayout mdxType="PageLayout">
      <Box as="main" sx={{
        pt: 4,
        "@media print": {
          a: {
            textDecoration: "none !important"
          }
        }
      }} mdxType="Box">
        <h2 {...{
          "id": "piotr-monwid-olechnowicz",
          "style": {
            "position": "relative"
          }
        }}><a parentName="h2" {...{
            "href": "#piotr-monwid-olechnowicz",
            "aria-label": "piotr monwid olechnowicz permalink",
            "className": "remark-autolink-headers__anchor before"
          }}><svg parentName="a" {...{
              "aria-hidden": "true",
              "viewBox": "0 0 24 24",
              "fill": "none",
              "stroke": "currentColor",
              "strokeWidth": "2"
            }}><path parentName="svg" {...{
                "d": "M10 13a5 5 0 0 0 7.54.54l3-3a5 5 0 0 0-7.07-7.07l-1.72 1.71"
              }}></path><path parentName="svg" {...{
                "d": "M14 11a5 5 0 0 0-7.54-.54l-3 3a5 5 0 0 0 7.07 7.07l1.71-1.71"
              }}></path></svg></a>{`Piotr Monwid-Olechnowicz`}</h2>
        <ul>
          <li parentName="ul"><a parentName="li" {...{
              "href": "mailto:hasparus@gmail.com"
            }}>{`hasparus@gmail.com`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://haspar.us"
            }}>{`https://haspar.us`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://github.com/hasparus"
            }}>{`https://github.com/hasparus`}</a></li>
        </ul>
        <h4 {...{
          "id": "open-source",
          "style": {
            "position": "relative"
          }
        }}><a parentName="h4" {...{
            "href": "#open-source",
            "aria-label": "open source permalink",
            "className": "remark-autolink-headers__anchor before"
          }}><svg parentName="a" {...{
              "aria-hidden": "true",
              "viewBox": "0 0 24 24",
              "fill": "none",
              "stroke": "currentColor",
              "strokeWidth": "2"
            }}><path parentName="svg" {...{
                "d": "M10 13a5 5 0 0 0 7.54.54l3-3a5 5 0 0 0-7.07-7.07l-1.72 1.71"
              }}></path><path parentName="svg" {...{
                "d": "M14 11a5 5 0 0 0-7.54-.54l-3 3a5 5 0 0 0 7.07 7.07l1.71-1.71"
              }}></path></svg></a>{`Open Source`}</h4>
        <p>{`I maintain Theme UI, and I tend to help out with problems I encounter.
My`}{` `}{`PRs were recently merged to `}<inlineCode parentName="p">{`LastContributions`}</inlineCode>{`.`}</p>
        <h3 {...{
          "id": "experience",
          "style": {
            "position": "relative"
          }
        }}><a parentName="h3" {...{
            "href": "#experience",
            "aria-label": "experience permalink",
            "className": "remark-autolink-headers__anchor before"
          }}><svg parentName="a" {...{
              "aria-hidden": "true",
              "viewBox": "0 0 24 24",
              "fill": "none",
              "stroke": "currentColor",
              "strokeWidth": "2"
            }}><path parentName="svg" {...{
                "d": "M10 13a5 5 0 0 0 7.54.54l3-3a5 5 0 0 0-7.07-7.07l-1.72 1.71"
              }}></path><path parentName="svg" {...{
                "d": "M14 11a5 5 0 0 0-7.54-.54l-3 3a5 5 0 0 0 7.07 7.07l1.71-1.71"
              }}></path></svg></a>{`Experience`}</h3>
        <h4 {...{
          "id": "edge--node",
          "style": {
            "position": "relative"
          }
        }}><a parentName="h4" {...{
            "href": "#edge--node",
            "aria-label": "edge  node permalink",
            "className": "remark-autolink-headers__anchor before"
          }}><svg parentName="a" {...{
              "aria-hidden": "true",
              "viewBox": "0 0 24 24",
              "fill": "none",
              "stroke": "currentColor",
              "strokeWidth": "2"
            }}><path parentName="svg" {...{
                "d": "M10 13a5 5 0 0 0 7.54.54l3-3a5 5 0 0 0-7.07-7.07l-1.72 1.71"
              }}></path><path parentName="svg" {...{
                "d": "M14 11a5 5 0 0 0-7.54-.54l-3 3a5 5 0 0 0 7.07 7.07l1.71-1.71"
              }}></path></svg></a>{`Edge & Node`}</h4>
        <ul>
          <li parentName="ul">{`Software Engineer`}<ul parentName="li">
              <li parentName="ul">{`May 2022 - Present`}</li>
            </ul></li>
        </ul>
        <p>{`Product Team. Working on Graph Explorer, Subgraph Studio, and the component
library.`}</p>
        <h4 {...{
          "id": "dethcrypto",
          "style": {
            "position": "relative"
          }
        }}><a parentName="h4" {...{
            "href": "#dethcrypto",
            "aria-label": "dethcrypto permalink",
            "className": "remark-autolink-headers__anchor before"
          }}><svg parentName="a" {...{
              "aria-hidden": "true",
              "viewBox": "0 0 24 24",
              "fill": "none",
              "stroke": "currentColor",
              "strokeWidth": "2"
            }}><path parentName="svg" {...{
                "d": "M10 13a5 5 0 0 0 7.54.54l3-3a5 5 0 0 0-7.07-7.07l-1.72 1.71"
              }}></path><path parentName="svg" {...{
                "d": "M14 11a5 5 0 0 0-7.54-.54l-3 3a5 5 0 0 0 7.07 7.07l1.71-1.71"
              }}></path></svg></a>{`dethcrypto`}</h4>
        <ul>
          <li parentName="ul">{`Open Source Engineer`}<ul parentName="li">
              <li parentName="ul">{`October 2021 - May 2022`}</li>
            </ul></li>
        </ul>
        <p>{`Maintaining TypeChain, eth-sdk and Earl to help dApp developers get into the
Pit of Success. Built
`}<a parentName="p" {...{
            "href": "https://github.com/dethcrypto/ethereum-code-viewer"
          }}>{`Ethereum Code Viewer`}</a>{`
to view multi-file contracts in VSCode in your browser.`}</p>
        <h4 {...{
          "id": "flick",
          "style": {
            "position": "relative"
          }
        }}><a parentName="h4" {...{
            "href": "#flick",
            "aria-label": "flick permalink",
            "className": "remark-autolink-headers__anchor before"
          }}><svg parentName="a" {...{
              "aria-hidden": "true",
              "viewBox": "0 0 24 24",
              "fill": "none",
              "stroke": "currentColor",
              "strokeWidth": "2"
            }}><path parentName="svg" {...{
                "d": "M10 13a5 5 0 0 0 7.54.54l3-3a5 5 0 0 0-7.07-7.07l-1.72 1.71"
              }}></path><path parentName="svg" {...{
                "d": "M14 11a5 5 0 0 0-7.54-.54l-3 3a5 5 0 0 0 7.07 7.07l1.71-1.71"
              }}></path></svg></a>{`Flick`}</h4>
        <ul>
          <li parentName="ul">{`Software Engineer`}<ul parentName="li">
              <li parentName="ul">{`August 2020 — October 2021`}</li>
            </ul></li>
        </ul>
        <p>{`Building a suite of apps for social media managers and influencers.
Maintaining internal tooling, including codegen plugins, shared libraries
and release automation. Decreased database costs by 8 times by changing
`}<em parentName="p">{`O(n)`}</em>{` db query into `}<em parentName="p">{`O(1)`}</em>{` read from custom queue.`}</p>
        <p>{`Main tools: TypeScript, GraphQL, React.`}</p>
        <h4 {...{
          "id": "chop-chop",
          "style": {
            "position": "relative"
          }
        }}><a parentName="h4" {...{
            "href": "#chop-chop",
            "aria-label": "chop chop permalink",
            "className": "remark-autolink-headers__anchor before"
          }}><svg parentName="a" {...{
              "aria-hidden": "true",
              "viewBox": "0 0 24 24",
              "fill": "none",
              "stroke": "currentColor",
              "strokeWidth": "2"
            }}><path parentName="svg" {...{
                "d": "M10 13a5 5 0 0 0 7.54.54l3-3a5 5 0 0 0-7.07-7.07l-1.72 1.71"
              }}></path><path parentName="svg" {...{
                "d": "M14 11a5 5 0 0 0-7.54-.54l-3 3a5 5 0 0 0 7.07 7.07l1.71-1.71"
              }}></path></svg></a>{`Chop-Chop`}</h4>
        <ul>
          <li parentName="ul">{`Senior Software Engineer`}<ul parentName="li">
              <li parentName="ul">{`August 2019 — August 2020`}</li>
            </ul></li>
          <li parentName="ul">{`React Developer`}<ul parentName="li">
              <li parentName="ul">{`December 2018 — August 2019`}</li>
            </ul></li>
        </ul>
        <p>{`I built libraries, web apps (mostly for real estate marketing), and
organized a company `}<a parentName="p" {...{
            "href": "https://www.meetup.com/pl-PL/Chop-Chop-Academy-Web-Apps"
          }}>{`tech meetup`}</a>{`. Main tools:
TypeScript, React and Markdown.`}</p>
        <h4 {...{
          "id": "explain-everything",
          "style": {
            "position": "relative"
          }
        }}><a parentName="h4" {...{
            "href": "#explain-everything",
            "aria-label": "explain everything permalink",
            "className": "remark-autolink-headers__anchor before"
          }}><svg parentName="a" {...{
              "aria-hidden": "true",
              "viewBox": "0 0 24 24",
              "fill": "none",
              "stroke": "currentColor",
              "strokeWidth": "2"
            }}><path parentName="svg" {...{
                "d": "M10 13a5 5 0 0 0 7.54.54l3-3a5 5 0 0 0-7.07-7.07l-1.72 1.71"
              }}></path><path parentName="svg" {...{
                "d": "M14 11a5 5 0 0 0-7.54-.54l-3 3a5 5 0 0 0 7.07 7.07l1.71-1.71"
              }}></path></svg></a>{`Explain Everything`}</h4>
        <ul>
          <li parentName="ul">{`JavaScript Developer`}<ul parentName="li">
              <li parentName="ul">{`July 2017 — October 2018`}</li>
            </ul></li>
        </ul>
        <p>{`Worked on a peer-to-peer interactive whiteboard using three.js. Transformed
large codebase from JQuery and decaffeinate CoffeeScript through Flow to
TypeScript and React.`}</p>
        <h4 {...{
          "id": "brighter-3d",
          "style": {
            "position": "relative"
          }
        }}><a parentName="h4" {...{
            "href": "#brighter-3d",
            "aria-label": "brighter 3d permalink",
            "className": "remark-autolink-headers__anchor before"
          }}><svg parentName="a" {...{
              "aria-hidden": "true",
              "viewBox": "0 0 24 24",
              "fill": "none",
              "stroke": "currentColor",
              "strokeWidth": "2"
            }}><path parentName="svg" {...{
                "d": "M10 13a5 5 0 0 0 7.54.54l3-3a5 5 0 0 0-7.07-7.07l-1.72 1.71"
              }}></path><path parentName="svg" {...{
                "d": "M14 11a5 5 0 0 0-7.54-.54l-3 3a5 5 0 0 0 7.07 7.07l1.71-1.71"
              }}></path></svg></a>{`Brighter 3D`}</h4>
        <ul>
          <li parentName="ul">{`Junior Software Developer`}<ul parentName="li">
              <li parentName="ul">{`June 2017`}</li>
            </ul></li>
        </ul>
        <p>{`Built a proof of concept of SketchUp renderer using C#, Unity3D, and Node.js
Google Cloud functions.`}</p>
        <h3 {...{
          "id": "education",
          "style": {
            "position": "relative"
          }
        }}><a parentName="h3" {...{
            "href": "#education",
            "aria-label": "education permalink",
            "className": "remark-autolink-headers__anchor before"
          }}><svg parentName="a" {...{
              "aria-hidden": "true",
              "viewBox": "0 0 24 24",
              "fill": "none",
              "stroke": "currentColor",
              "strokeWidth": "2"
            }}><path parentName="svg" {...{
                "d": "M10 13a5 5 0 0 0 7.54.54l3-3a5 5 0 0 0-7.07-7.07l-1.72 1.71"
              }}></path><path parentName="svg" {...{
                "d": "M14 11a5 5 0 0 0-7.54-.54l-3 3a5 5 0 0 0 7.07 7.07l1.71-1.71"
              }}></path></svg></a>{`Education`}</h3>
        <h4 {...{
          "id": "bsc-computer-science-university-of-wrocław",
          "style": {
            "position": "relative"
          }
        }}><a parentName="h4" {...{
            "href": "#bsc-computer-science-university-of-wroc%C5%82aw",
            "aria-label": "bsc computer science university of wrocław permalink",
            "className": "remark-autolink-headers__anchor before"
          }}><svg parentName="a" {...{
              "aria-hidden": "true",
              "viewBox": "0 0 24 24",
              "fill": "none",
              "stroke": "currentColor",
              "strokeWidth": "2"
            }}><path parentName="svg" {...{
                "d": "M10 13a5 5 0 0 0 7.54.54l3-3a5 5 0 0 0-7.07-7.07l-1.72 1.71"
              }}></path><path parentName="svg" {...{
                "d": "M14 11a5 5 0 0 0-7.54-.54l-3 3a5 5 0 0 0 7.07 7.07l1.71-1.71"
              }}></path></svg></a>{`BSc Computer Science, University of Wrocław`}</h4>
        <p>{`I learned to google really hard and read research papers.
Hit`}{` `}{`me`}{` `}{`up`}{` `}{`about`}{` `}{`logic, discrete maths, Haskell, Prolog,
C or x86-64 assembly.`}</p>
      </Box>
      <Box sx={{
        "@media print": {
          display: "none"
        }
      }} mdxType="Box">
  <hr />
  <PrintItButton mdxType="PrintItButton" />
      </Box>
    </PageLayout>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      